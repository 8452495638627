import React from 'react'
import availableLangs from '@localization/availableLangs'
import SeoContainer from '@containers/SeoContainer'
import LandingLayout from '@layouts/LandingLayout'
import LocalizedLink from '@common/links/LocalizedLink'
import {
  TextPageContainer,
  List,
  ListItem,
  ListItemWrapper,
  LastUpdated,
  PageTitle,
  Title,
  Paragraph,
  LinkWrapper,
  Mark,
} from '@common/typography'

const TermsPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.de} pagePath='terms' />

      <LandingLayout>
        <TextPageContainer>
          <LastUpdated>Letzte Aktualisierung: 31.05.2021</LastUpdated>
          <PageTitle>Allgemeine Geschäftsbedingungen</PageTitle>
          <List>
            <ListItem>
              <Title>1. Einleitung</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>1.1.</Mark>
                    <Paragraph>
                      Diese Allgemeinen Geschäftsbedingungen beziehen sich auf die
                      Websiten, Produkte oder Marken infludata bzw. infludata.com und
                      paymentpunks bzw. Paymentpunks.com der weCreate Data GmbH, Obere
                      Augartenstraße 2/24, 1020 Wien, Österreich (im Folgenden "weCreate
                      Data", "Wir", "Uns") und alle anderen Websites oder Produkte, die
                      uns gehören und unsere Produkte und Dienstleistungen enthalten
                      (unsere „Website" oder "Dienstleistungen"). Diese allgemeinen
                      Geschäftsbedingungen werden zwischen uns und dem Besucher unserer
                      Websiten oder - falls zutreffend - mit dem Nutzer oder Käufer
                      unserer Dienstleistungen vereinbart (im Folgenden als "Sie",
                      "Nutzer", "Auftraggeber" oder "Kunde").
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>1.2.</Mark>
                    <Paragraph>
                      Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen aufmerksam
                      durch, bevor Sie einen Bereich unserer Website nutzen oder bevor Sie
                      Produkte oder Dienstleistungen erwerben. Durch die Nutzung jeglichen
                      Bereichs unserer Website oder die Registrierung bei uns über die
                      Website erklären Sie Ihr Einverständnis mit:
                    </Paragraph>
                  </ListItemWrapper>
                  <List>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>a.</Mark>
                        <Paragraph>diesen Allgemeinen Geschäftsbedingungen;</Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>b.</Mark>
                        <Paragraph>
                          den &nbsp;
                          <LinkWrapper>
                            <LocalizedLink page='privacy'>
                              Datenschutzrichtlinien
                            </LocalizedLink>
                          </LinkWrapper>
                          ;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>c.</Mark>
                        <Paragraph>
                          jeglichen Geschäftsbedingungen und Regelungen in Bezug auf
                          Werbeaktionen oder Sonderangebote, die von Zeit zu Zeit
                          Anwendung finden; (zusammen: die „Nutzungsbedingungen").
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>1.3.</Mark>
                    <Paragraph>
                      Falls die Nutzungsbedingungen in irgendeine andere Sprache übersetzt
                      werden, dient die Übersetzung ausschließlich zur Information, und im
                      Falle irgendeiner Abweichung zwischen der Übersetzung und der
                      deutschsprachigen Version, ist die deutschsprachige Version
                      verbindlich.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>1.4.</Mark>
                    <Paragraph>
                      Durch Ihre fortgesetzte Nutzung der Website oder einer Bestellung
                      erklären Sie sich mit den Nutzungsbedingungen einverstanden.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>1.5.</Mark>
                    <Paragraph>
                      Die Nutzungsbedingungen regeln Ihre Vertragsvereinbarung mit uns.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>1.6.</Mark>
                    <Paragraph>
                      Sollte es irgendeinen Widerspruch oder irgendeine Unstimmigkeit in
                      den Vertragsbedingungen geben, gilt die in Absatz 1.2 festgesetzte
                      Rangordnung.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>1.7.</Mark>
                    <Paragraph>
                      Wir verwenden Cookies, um Ihren Besuch der Website bestmöglich zu
                      gestalten. Wenn Sie irgendeinen Teil unserer Website weiterhin
                      nutzen, ohne Ihre Cookie-Einstellungen zu verändern, setzen wir
                      voraus, dass Sie unserer Cookie-Verwendung auf der Website
                      zustimmen. Sie können Ihre Cookie-Einstellungen jederzeit ändern,
                      allerdings könnten in diesem Fall einige Funktionen auf unserer
                      Website nicht zur Verfügung stehen. Weitere Informationen finden Sie
                      in unserer&nbsp;
                      <LinkWrapper>
                        <LocalizedLink page='privacy'>
                          Datenschutzrichtlinien
                        </LocalizedLink>
                      </LinkWrapper>
                      .
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>2. Veränderungen der Nutzungsbedingungen</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>2.1.</Mark>
                    <Paragraph>
                      Wir behalten uns das Recht vor, jederzeit Änderungen an den
                      Nutzungsbedingungen vorzunehmen. Wir werden angemessene Maßnahmen
                      ergreifen um sicherzustellen, dass Sie über jegliche wichtige
                      Änderung über einen angemessenen Weg (zum Beispiel über Email oder
                      mittels einer Nachricht auf der Website) informiert werden.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>3. Nutzung der Website</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>3.1.</Mark>
                    <Paragraph>
                      Sie sollen die Website ausschließlich zu rechtmäßigen Zwecken
                      nutzen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>3.2.</Mark>
                    <Paragraph>
                      Sie sind nicht dazu befugt, die Website oder irgendwelche Teile von
                      ihr in irgendeiner Form ohne unsere ausdrückliche Einverständnis zu
                      vervielfältigen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>3.3.</Mark>
                    <Paragraph>Sie dürfen nicht:</Paragraph>
                  </ListItemWrapper>
                  <List>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>a.</Mark>
                        <Paragraph>
                          versuchen, sich unautorisierten Zugang zu der Website, zu ihrem
                          Server oder irgendeinem anderen Server, Computer oder einer
                          Datenbank zu verschaffen, die mit unserer Website verbunden ist;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>b.</Mark>
                        <Paragraph>
                          unsere Website über eine Denial-of-Service-Attacke oder eine
                          Distributed-Denial-of-Service-Attacke angreifen; und
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>c.</Mark>
                        <Paragraph>
                          die Website wissentlich durch Viren, Trojaner, Würmer,
                          Logikbomben, Zeitbomben, Keylogger, Spyware, Adware oder
                          jegliches andere schädliche Material, das zur negativen
                          Beeinflussung der Funktionsweise von jeglicher Computer-Software
                          oder -Hardware entwickelt wurde, infizieren.
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>3.4</Mark>
                    <Paragraph>
                      Sie machen sich eventuell einer Straftat schuldig, wenn Sie
                      irgendeine der in Abschnitt 3.3. aufgeführten Bedingungen verletzen.
                      Wir werden eine solche Verletzung den zuständigen Vollzugsbehörden
                      melden und mit diesen Behörden kooperieren, indem wir Informationen
                      über Sie bereitstellen. Im Falle einer solchen Verletzung wird Ihr
                      Recht zur Nutzung unserer Website unmittelbar erlöschen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>3.5</Mark>
                    <Paragraph>Sie sollen die Website nicht nutzen:</Paragraph>
                  </ListItemWrapper>
                  <List>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>a.</Mark>
                        <Paragraph>
                          indem Sie gegen geltende örtliche, nationale oder internationale
                          Gesetze oder Verordnungen verstoßen;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>b.</Mark>
                        <Paragraph>
                          in irgendeiner rechtswidrigen oder betrügerischen Weise oder in
                          einer Weise, die rechtswidrige oder betrügerische Absichten oder
                          Folgen hat;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>c.</Mark>
                        <Paragraph>
                          zu irgendeinem Zweck, der schädlich, bedrohlich, diffamierend,
                          verleumderisch, beleidigend, hasserfüllt, sexistisch oder
                          diskriminierend ist oder so verstanden wird, Anstoß erregen
                          könnte oder anderweitig beleidigend ist;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>d.</Mark>
                        <Paragraph>
                          um Minderjährige in irgendeiner Weise zu schaden, sie zu
                          bedrohen oder den Versuch zu unternehmen, ihnen Schaden
                          zuzufügen;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>e.</Mark>
                        <Paragraph>
                          um irgendein unerwünschtes oder unautorisiertes Werbe- oder
                          Promotion-Material oder irgendeine andere Form von ähnlicher
                          Werbung (Spam) zu versenden oder zur Versandbereitstellung zu
                          nutzen;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>f.</Mark>
                        <Paragraph>
                          um andere Personen zu „stalken" oder anderweitig zu belästigen;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>g.</Mark>
                        <Paragraph>
                          sich als eine andere natürliche oder juristische Person,
                          hierunter - aber nicht ausschließlich - Vertreter von weCreate
                          Data, auszugeben oder Ihre Verbindung mit einer natürlichen oder
                          juristischen Person fälschlich oder anderweitig unangemessen
                          darzustellen;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>h.</Mark>
                        <Paragraph>
                          um Kopfzeilen zu fälschen oder Identifikationsmerkmale
                          anderweitig zu manipulieren, um die Herkunft der über die
                          Website übertragenen Inhalte zu verschleiern;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>i.</Mark>
                        <Paragraph>
                          um den normalen Dialogfluss zu unterbrechen oder in einer
                          anderen Weise zu agieren, die andere Nutzer in ihrer Fähigkeit
                          zum Echtzeitaustausch einschränkt;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>j.</Mark>
                        <Paragraph>
                          um persönliche Daten anderer Nutzer unserer Website in
                          Verbindung mit den in den Nutzungsbedingungen untersagten
                          Verhaltensweisen und Aktivitäten zu sammeln oder zu speichern.
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>3.6</Mark>
                    <Paragraph>
                      Sie dürfen nicht einschränken, beschädigen oder zerstören:
                    </Paragraph>
                  </ListItemWrapper>
                  <List>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>a.</Mark>
                        <Paragraph>irgendeinen Teil unserer Website;</Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>b.</Mark>
                        <Paragraph>
                          irgendein Netzwerk, in dem unsere Website gespeichert ist;
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>c.</Mark>
                        <Paragraph>
                          irgendeine zur Bereitstellung unserer Website verwendete
                          Software.
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>3.7</Mark>
                    <Paragraph>
                      Wir übernehmen keine Haftung für irgendwelche Verluste oder Schäden,
                      die durch eine Distributed-Denial-of-Service-Attacke, Viren oder
                      durch anderes technologisch schädliches Material verursacht wird,
                      die Ihre Computerausrüstung, Computerprogramme, Daten oder anderes
                      eigenes Material aufgrund Ihrer Nutzung unserer Website oder Ihres
                      Downloads von irgendeinem auf dieser Website oder auf irgendeiner
                      verlinkten Website bereitgestellten Materials infizieren können.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>4. Registrierung, Konto-Eröffnung &amp; Konto-Verwaltung</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>4.1.</Mark>
                    <Paragraph>
                      Durch Ihre Registrierung bei uns eröffnen Sie ein Konto („Konto")
                      bei uns.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>4.2.</Mark>
                    <Paragraph>
                      Wenn Sie sich registrieren und bei uns ein Konto eröffnen, werden
                      wir Sie dazu auffordern, ein Email und ein Password zu wählen. Sie
                      sollten sicherstellen, dass diese Details privat bleiben. Sie sind
                      für alle mit Ihrem Login (wo die korrekten Sicherheitsinformationen
                      zur Verfügung gestellt werden) verbundenen Aktivitäten
                      verantwortlich. Wenn Sie Ihre Email und Ihr Password verlieren oder
                      vergessen, oder wenn Sie glauben, dass eine dritte Person Kenntnis
                      von ihnen hat, sollten Sie uns unverzüglich kontaktieren, damit
                      Ihnen neue Sicherheitsdetails zur Verfügung gestellt werden können.
                      Diese neuen Details werden Ihnen per Email zugeschickt.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>4.3.</Mark>
                    <Paragraph>
                      Wenn eine andere Person auf Ihr Konto zugreift, sind Sie allein für
                      alle ihre Aktionen verantwortlich, unabhängig davon, ob der Zugriff
                      von Ihnen genehmigt wurde. Hiermit halten Sie uns schadlos und frei
                      von jeglicher Haftung für Kosten, Ansprüche, Ausgaben und Schäden
                      jeder Art, die in Verbindung mit der Nutzung oder dem Zugriff auf
                      Ihr Konto durch irgendeine dritte Partei entstehen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>5. Leistungsgegenstand</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>5.1.</Mark>
                    <Paragraph>
                      Geschäftsinhalt von weCreate Data ist die Erbringung von technischen
                      Dienstleistungen, vor allem der Bereitstellung von Daten und
                      Auswertungen von Social-Media Profilen, die für Werbe- und
                      Marketingzwecke genutzt werden. Es handelt sich insbesondere um
                      Nutzer öffentlicher Profile auf der Plattform Instagram und TikTok.
                      Die bereitgestellten Daten werden automatisiert verarbeitet und
                      basieren auf Schätzungen und Algorithmen von weCreate Data oder auf
                      Daten, die von Instagram und TikTok oder deren Nutzer öffentlich
                      bereitgestellt werden.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>5.2.</Mark>
                    <Paragraph>
                      Grundlage aller zwischen weCreate Data und dem Auftraggeber
                      bestehenden Verbindungen und Verträge sind diese Allgemeinen
                      Geschäftsbedingungen (AGB). Davon abweichende Regelungen und AGB -
                      etwa des Auftraggebers - besitzen keine Gültigkeit, es sei denn,
                      weCreate Data stimmt ihnen ausdrücklich und schriftlich zu.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>5.3.</Mark>
                    <Paragraph>
                      weCreate Data steht in keinem Verhältnis zu Facebook Inc., Bytedance
                      oder irgendeiner anderen Plattform.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>6. Angebot, Vertragsabschluss und Testzeitraum</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>6.1.</Mark>
                    <Paragraph>
                      Angebote - sowohl persönlich, als auch im Internet öffentlich zur
                      Verfügung gestellt - sind freibleibend. Bestellungen bzw.
                      Erklärungen zur Angebotsannahme seitens des Auftraggebers müssen
                      schriftlich oder elektronisch erfolgen und bedürfen zu ihrem
                      Wirksamwerden der elektronischen oder schriftlichen Zustimmung von
                      weCreate Data.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>6.2.</Mark>
                    <Paragraph>
                      Für sämtliche Angebote, Leistungen und Lieferungen gelten diese AGB
                      als Rechtsgrundlage. Abweichende oder zusätzliche Vereinbarungen
                      müssen schriftlich fixiert werden, insbesondere Konditionen seitens
                      des Auftraggebers, die auch dann keine automatische Gültigkeit
                      erlangen, wenn weCreate Data sie im Rahmen eines
                      Dienstleistungsvertrags nicht explizit zurückweist.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>6.3.</Mark>
                    <Paragraph>
                      Handlet es sich um ein kostenloses Angebot, so tritt die Tätigkeit
                      von weCreate Data nach Absenden der Registrierung in Kraft. Im Falle
                      eines zahlungspflichtigen Abonnements tritt die Tätigkeit von
                      weCreate Data nach der schriftlichen beiderseitigen Zustimmung in
                      Kraft und wird mit Versand einer schriftlichen Bestellbestätigung
                      via Email (z.B. "Benachrichtigung über den Start eines Trials")
                      bestätigt. Im Falle eines Abonnements werden die Leistungen - wenn
                      nicht anders schriftlich vereinbart - nach Ablauf des
                      Leistungszeitraum automatisch verlängert, wenn keine Kündigung bzw.
                      keine fristgerechte Kündigung erfolgt ist (siehe Punkt 6).
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>6.4.</Mark>
                    <Paragraph>
                      Das Angebot eines Testzeitraums ("Trials") darf von Ihnen bzw. des
                      Ihnen angehörigen Unternehmens nur einmal innerhalb von 12 Monaten
                      in Anspruch genommen werden, sofern dies nicht anders schriftlich
                      mit uns vereinbart wurde. Wird ein weiterer Testzeitraum innerhalb
                      dieser Frist gestartet, so behält sich weCreate Data das Recht vor,
                      die Kosten für den ersten Monat in Rechnung zu stellen und, falls
                      möglich, diese von der hinterlegten Zahlungsart abzubuchen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>6.5.</Mark>
                    <Paragraph>
                      Mit Abschluss der Bestellung bestätigen Sie, dass Sie - sollten Sie
                      im Namen eines Unternehmens handeln - die nach geltendem Recht
                      erforderlichen Berechtigungen besitzen, im Namen dieses Unternehmens
                      Verträge abzuschließen. Sie bestätigen ebenfalls, dass Sie
                      authorisiert sind, das angegebene Zahlungsmittel für Zahlungen zu
                      verwenden und es sich um ein gültiges Zahlungsmittel handelt.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>7. Leistungsbeschreibung</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>7.1.</Mark>
                    <Paragraph>
                      Der Umfang der zu erbringenden Leistungen ergibt sich aus der
                      Leistungsbeschreibung des auf der Webseite "infludata.com"
                      ausgewählten Produkts und der schriftlichen Auftragsbestätigung
                      durch weCreate Data (Bestellbestätigung). Nachträgliche Änderungen
                      des Leistungsinhaltes bedürfen der schriftlichen Bestätigung durch
                      weCreate Data. Kommt es im Rahmen der Bestellung zu technischen
                      Problemen oder wird ein falsches Produkt bereitgestellt, ist
                      weCreate Data sofort nach Kenntnisnahme davon zu unterrichten.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>7.2.</Mark>
                    <Paragraph>
                      Alle bereitgestellten Daten von Instagramnutzern und TikTok-Nutzern
                      sind unverbindlich, stammen von öffentlich zugänglichen Quellen und
                      können Fehler enthalten. Alle Werte
                      sind lediglich Schätzungen von weCreate Data und könnten nicht dem aktuellen Stand entsprechen. Die Dienstleistungen - insbesondere die
                      Suchfunktion - stellen lediglich eine Hilfestellung bei der
                      Recherche dar.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>7.3.</Mark>
                    <Paragraph>
                      Die Dienstleistungen dürfen in jedem Fall nur für den
                      vorhergesehenen Zweck verwendet werden. Wenn es nicht anders
                      angegeben ist, entspricht dies Werbe- und Marketingzwecke des
                      Auftraggebers. Insbesondere ist die Verwendung der Dienstleistungen
                      zu folgenden Zwecken untersagt:
                    </Paragraph>
                  </ListItemWrapper>
                  <List>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>a.</Mark>
                        <Paragraph>
                          Versand von Massen-Emails oder Nachrichten an bereitgestellte
                          Kontaktadressen ("Spam")
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>b.</Mark>
                        <Paragraph>
                          Nutzung der Daten in einer Weise, die gegen bestehendes Recht,
                          entweder im ansässigen Staat von weCreate Data, oder auch im
                          ansässigen Staat des Auftraggebers verstoßen
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>c.</Mark>
                        <Paragraph>
                          (Teil-) automatisierte Nutzung der Plattform "infludata.com"
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>d.</Mark>
                        <Paragraph>
                          Speicherung oder Abrufen unüblich großer Mengen von Daten. Diese
                          Grenze ist erreicht, bei
                          <br />
                          - der Speicherung von 500 oder mehr Datensätzen in Collections
                          bzw. Favoriten in einem Zeitfenster von 12 Stunden
                          <br />
                          - Öffnen von 1000 oder mehr Influencern in einem Zeitfenster von
                          12 Stunden
                          <br />
                          - Ausführen von 500 oder mehr Suchen in einem Zeitfenster von 12
                          Stunden
                          <br />
                          weCreate Data behält sich das Recht vor, darüber hinausgehende
                          Aktionen des Auftraggebers innerhalb dieses Zeitfensters zu
                          blockieren. Sollten diese Grenzen mehrmals in Folge erreicht
                          werden, behält sich weCreate Data das Recht vor, weitere
                          Schritte einzuleiten.
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>e.</Mark>
                        <Paragraph>
                          Weitergabe gewonnener Informationen oder Daten an Dritte
                          außerhalb der Organisation des Auftraggebers (wenn nicht
                          schriftlich anders vereinbart)
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>7.4.</Mark>
                    <Paragraph>
                      Der Kunde wird weCreate Data zeitgerecht und vollständig alle
                      Informationen und Unterlagen zugänglich machen, die für die
                      Erbringung der Leistung erforderlich sind.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>7.5.</Mark>
                    <Paragraph>
                      Der Kunde ist verpflichtet, die für die Durchführung des Auftrages
                      zur Verfügung gestellten Unterlagen auf Urheber-, Kennzeichenrechte
                      oder sonstige Rechte Dritter zu überprüfen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>8. Haftung, Gewährleistung und Garantie</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.1.</Mark>
                    <Paragraph>
                      weCreate Data gewährleistet, dass die erbrachten Leistungen der
                      vereinbarten Leistungsbeschreibung entsprechen. Für Leistungen
                      Dritter, etwa den fehlerfreien und sicheren Netzwerkbetrieb,
                      übernimmt weCreate Data keine Gewährleistung. Allfällige
                      Schadenersatzforderungen des Auftraggebers sind von diesem -
                      unbeschadet gesetzlicher oder vertraglich vereinbarter Fristen -
                      binnen 3 Monaten ab Kenntnis des Schadens und des Schädigers bei
                      sonstigem Verfall schriftlich anzumelden. Die Untersuchung- und
                      Rügeobliegenheit des Auftraggebers bleibt davon gänzlich unberührt.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.2.</Mark>
                    <Paragraph>
                      Als Fehler in der Leistungserbringung von weCreate Data gelten
                      lediglich reproduzierbare Fehler, die auf eine qualitativ
                      mangelhafte Leistung seitens weCreate Data zurückgehen. Andere
                      Ursachen, etwa fehlerhafte Bedienung, fremde Optimierungsmaßnahmen
                      und inhaltliche Veränderungen, schadhafte Hardware, widrige
                      Umgebungsbedingungen oder schlechte Datenqualität gelten nicht als
                      Fehler im Sinne der Gewährleistung.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.3.</Mark>
                    <Paragraph>
                      Das Risiko der rechtlichen Zulässigkeit der durch weCreate Data
                      erarbeiteten und durchgeführten Maßnahmen wird vom Kunden getragen.
                      Das gilt insbesondere für den Fall, dass die Aktionen und Maßnahmen
                      gegen Vorschriften des Wettbewerbsrechts, des Urheberrechts und der
                      speziellen Werberechtsgesetze verstoßen. Der Kunde stellt weCreate
                      Data von derartigen Ansprüchen Dritter frei. Dieses umfasst auch die
                      Rechtsverfolgungskosten im Falle einer Inanspruchnahme von weCreate
                      Data durch Dritte. Die Anmeldung solcher Bedenken durch weCreate
                      Data beim Kunden erfolgt unverzüglich nach Bekanntwerden in
                      schriftlicher Form. Erachtet weCreate Data für eine durchzuführende
                      Maßnahme eine wettbewerbsrechtliche Prüfung durch eine besonders
                      sachkundige Person oder Institution für erforderlich, so trägt nach
                      Absprache mit weCreate Data die Kosten hierfür der Kunde.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.4.</Mark>
                    <Paragraph>
                      weCreate Data haftet nur für Schäden, die sie oder ihre
                      Erfüllungsgehilfen vorsätzlich oder grob fahrlässig herbeigeführt
                      haben. Die Haftung von weCreate Data wird beschränkt auf die
                      typischerweise bei der betreffenden Dienstleistung der von weCreate
                      Data zu erwartenden Schäden. Diese Haftungsbeschränkung gilt auch
                      für Folgeschäden und mittelbare Schäden im Fall einfacher
                      Fahrlässigkeit, mit Ausnahme von Lebens-, Körper- oder
                      Gesundheitsverletzungen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.5.</Mark>
                    <Paragraph>
                      weCreate Data übernimmt keine Gewähr für die vollständige Vermeidung
                      von Ausfällen oder Umsatzeinbußen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.6.</Mark>
                    <Paragraph>
                      Von der Gewährleistung ausgeschlossen sind grundsätzlich solche
                      Fehler, die durch äußere Einflüsse, Bedienungsfehler oder nicht von
                      weCreate Data durchgeführte Änderungen, Ergänzungen, Ein- oder
                      Ausbauten, Reparaturversuche des Kunden oder sonstige Manipulationen
                      entstehen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.7.</Mark>
                    <Paragraph>
                      Die Leistungen - insbesondere die durch weCreate Data
                      bereitgestellten Daten - können Fehler enthalten und es kann kann
                      keine Garantie auf Richtigkeit gegeben werden. Der Auftraggeber
                      selbst ist verpflichtet, die Daten auf Richtigkeit zu überprüfen.
                      Weiter übernimmt weCreate Data keine Haftung für Schäden, die aus
                      fehlerhaften bereitgestellten Daten oder Informationen
                      hervorgegangen sind. Es wird darauf hingewiesen, dass die
                      bereitgestellten Daten entweder auf automatisierten Schätzungen und
                      Algorithmen beruhen oder von Instagram, TikTook oder deren Nutzer
                      bereitgestellt wurden. Diese Informationen werden nicht manuell
                      überprüft.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.8.</Mark>
                    <Paragraph>
                      Wird weCreate Data grobe Fahrlässigkeit nachgewiesen, ist die
                      Haftung für Schäden auf die Höhe der durch die Leistungen des
                      Netto-Auftragswerts des vergangenen Monats begrenzt.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.9.</Mark>
                    <Paragraph>
                      weCreate Data leistet keinen Schadensersatz, wenn die Ursache einer
                      Pflichtverletzung außerhalb des weCreate Data-Verantwortungsbereichs
                      liegt, etwa weil Dritte die erforderlichen Zuliefererdienste nicht
                      ordnungsgemäß erbringen oder technische Probleme auftreten, für die
                      Dritte verantwortlich sind.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>8.10.</Mark>
                    <Paragraph>
                      Der Auftraggeber haftet für Schäden, die weCreate Data vorsätzlich
                      oder durch grobe Fahrlässigkeit entstehen und auf eine
                      missbräuchliche Verwendung der Dienstleistungen beruhen.
                      Insbesondere haftet der Auftraggeber für Schäden oder entgangene
                      Gewinne, die durch die Weitergabe der gewonnen Daten bzw. der
                      Zugangsdaten an Dritte entstanden sind. Der Auftraggeber haftet
                      ebenfalls für entstandene Schäden durch Ausfälle des Systems, sofern
                      diese auf eine missbräuchliche Verwendung zurückzuführen sind.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>9. Preise, Zahlungen, Fälligkeit</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>9.1.</Mark>
                    <Paragraph>
                      Alle Preise verstehen sich netto zuzüglich gesetzlicher
                      Umsatzsteuer, wenn nicht anders angegeben.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>9.2.</Mark>
                    <Paragraph>
                      Die Rechnungsstellung erfolgt jeweils zu Beginn der jeweiligen
                      Zahlungsperiode und wird durch die hinterlegte Zahlungsmethode
                      beglichen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>9.3.</Mark>
                    <Paragraph>
                      Der Auftraggeber ist verpflichtet die Zahlungsdaten aktuell zu
                      halten und weCreate Data im Falle von Änderungen unmittelbar zu
                      unterrichten.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>9.4.</Mark>
                    <Paragraph>
                      Die von weCreate Data bei Zahlungsverzug seitens des Auftraggebers
                      erhobenen Zinsen belaufen sich auf den Basiszinssatz der
                      Europäischen Zentralbank zuzüglich 5 Prozent. Kompensationen für
                      weitere Schäden, die durch die Verzögerung entstehen, können darüber
                      hinaus ebenfalls eingefordert werden.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>10. Vertragslaufzeit, Kündigung</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>10.1.</Mark>
                    <Paragraph>
                      Vertragslaufzeiten, Kündigungsfristen und Verlängerungen werden
                      individuell vereinbart und im Rahmen des Angebotes genau festgelegt.
                      Wenn im Angebot nicht näher beschrieben, gelten die
                      Abrechnungszeiträume monatlich und verlängern sich automatisch am
                      Ende der jeweiligen Abrechnungsperiode. Kündigungen können
                      spätestens vor Beginn der Abrechnungsperiode erfolgen. Wird während
                      einer Abrechnungsperiode gekündigt, so erfolgt die Kündigung erst
                      mit Ende der jeweiligen Abrechnungsperiode.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>10.2.</Mark>
                    <Paragraph>
                      Sollten wichtige Gründe vorliegen, ist unabhängig von den
                      Mindestfristen eine sofortige Vertragskündigung möglich. Als solche
                      Gründe gelten 1. ausbleibende Zahlungen seitens des Auftraggebers,
                      2. Verstöße gegen vertraglich festgelegte Pflichten, etwa die zur
                      Geheimhaltung oder die zweckgemäße Nutzung. Außerdem wird ein die
                      Kündigung rechtfertigender Grund angenommen, sobald von dritter
                      Seite Einsprüche gegen die Verwendung der vom Auftraggeber genannten
                      und eingestellten Begriffe und Inhalte erhoben werden.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>11. Mitwirkung</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>11.1.</Mark>
                    <Paragraph>
                      Alle für die Erbringung der vertraglich vereinbarten Leistungen
                      erforderlichen Informationen und sonstigen Mitwirkungsleistungen
                      müssen weCreate Data vom Auftraggeber zugänglich gemacht bzw.
                      gewährt werden.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>11.2.</Mark>
                    <Paragraph>
                      Die Qualität der von weCreate Data erbrachten Leistungen muss vom
                      Auftraggeber unverzüglich evaluiert, dabei festgestellte Mängel
                      müssen sofort schriftlich und detailliert gegenüber weCreate Data
                      dargestellt werden. Es gilt eine Frist zur Mängelrüge von 14 Tagen
                      ab Leistungserbringung.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>11.3.</Mark>
                    <Paragraph>
                      Sollte der Auftraggeber seine Pflichten zur Mitwirkung nicht
                      erfüllen, so ist auch weCreate Data von der Pflicht zur
                      Leistungserbringung befreit.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>11.4.</Mark>
                    <Paragraph>
                      Der Auftraggeber trägt dafür Sorge, dass weCreate Data, wenn
                      notwendig, Zugriff (oder vergleichbares) auf die zu optimierenden
                      Webseiten erhält.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>11.5.</Mark>
                    <Paragraph>
                      Wenn schriftlich nicht anders vereinbart, erhält weCreate Data zum
                      Zwecke des Eigenmarketings das Recht, ein bestehendes
                      Vertragsverhältnis unter Nennung des Auftraggebers, sowie in
                      Präsentationsunterlagen, öffentlich zu machen. Darüber hinaus
                      verpflichten sich beide Seiten, zu Details des Vertragsverhältnisses
                      Stillschweigen zu bewahren.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>12. Geheimhaltung und Datenschutz</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>12.1.</Mark>
                    <Paragraph>
                      Die Nutzung der Dienstleistung ist nur dem registrierten Nutzer
                      gestattet. Eine Weitergabe der Anmeldedaten an Dritte ist untersagt.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>12.2.</Mark>
                    <Paragraph>
                      weCreate Data ist verpflichtet, alle Kenntnisse die sie aufgrund
                      eines Auftrags vom Kunden erhält, zeitlich unbeschränkt streng
                      vertraulich zu behandeln und sowohl ihre Mitarbeiter, als auch von
                      ihr herangezogene Dritte ebenfalls in gleicher Weise zu absolutem
                      Stillschweigen zu verpflichten.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>12.3.</Mark>
                    <Paragraph>
                      Der Kunde verpflichtet sich, alle ihm bei der Vertragsdurchführung
                      von weCreate Data oder im Auftrag von weCreate Data handelnden
                      Personen zugehenden oder bekanntwerdenden Geschäfts- und
                      Betriebsgeheimnisse oder als vertraulich bezeichnete Informationen
                      geheim zu halten. Dies gilt insbesondere für Informationen über
                      Algorithmen und Technologien der Social-Media Plattformen, soweit
                      diese nicht allgemein bekannt sind oder auch Daten und
                      Informationen, die durch die Dienstleistung dem Auftraggeber bekannt
                      geworden sind. Diese Verpflichtung gilt zudem auch zwei Jahre über
                      das Vertragsende hinaus.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>12.4.</Mark>
                    <Paragraph>
                      weCreate Data speichert alle Daten des Kunden während der Dauer des
                      Vertragsverhältnisses elektronisch, soweit dies zur Erfüllung des
                      Vertragszwecks, insbesondere für Abrechnungszwecke, erforderlich
                      ist. Außerdem werden Daten über die Nutzung (Zeitpunkt der Eingabe,
                      Eingabewerte, Anzahl der Eingaben, IP des Nutzer, auf der Plattform
                      gespeicherte Daten des Nutzers, Daten über Datenexporte des Nutzers
                      usw.) gespeichert und verarbeitet, um Produkte zu verbessern, Fehler
                      in der Software zu finden und zu eliminieren, oder auch um
                      missbräuchliche Nutzungen festzustellen. Die erhobenen Daten
                      verarbeitet und nutzt weCreate Data auch anonymisiert zur Beratung
                      seiner Kunden, zur Werbung und zur Marktforschung für eigene Zwecke
                      und zur bedarfsgerechten Gestaltung seiner
                      Telekommunikationsleistungen. weCreate Data wird dem Kunden auf
                      Verlangen jederzeit über den gespeicherten Datenbestand, soweit er
                      ihn betrifft, vollständig und unentgeltlich Auskunft erteilen und
                      sämtliche gespeicherte personenbezogene Daten auf Kundenwunsch
                      löschen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>12.5.</Mark>
                    <Paragraph>
                      Die gespeicherten Bestandsdaten umfassen die persönlichen Daten,
                      nämlich Name/Firma, Beruf, Geburtsdatum, Firmenbuchnummer,
                      Vertretungsbefugnisse, Ansprechperson, Geschäftsanschrift und
                      sonstige Adressen des Kunden, Telefonnummer, Telefaxnummer,
                      E-Mail-Adresse, Bankverbindungen und UID-Nummer, sowie die
                      Zugangsdaten ('Login') des Unternehmens.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>12.6.</Mark>
                    <Paragraph>
                      weCreate Data wird weder diese Daten noch den Inhalt privater
                      Nachrichten des Kunden ohne dessen Einverständnis an Dritte
                      weiterleiten. Dies gilt insoweit nicht, als weCreate Data
                      verpflichtet ist, Dritten, insbesondere staatlichen Stellen, solche
                      Daten zu offenbaren oder soweit international anerkannte technische
                      Normen dies vorsehen und der Kunde nicht widerspricht.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>12.7.</Mark>
                    <Paragraph>
                      weCreate Data weist den Kunden ausdrücklich darauf hin, dass der
                      Datenschutz für Datenübertragungen in offenen Netzen wie dem
                      Internet nach dem derzeitigen Stand der Technik nicht umfassend
                      gewährleistet werden kann.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>12.8.</Mark>
                    <Paragraph>
                      Der Kunde weiß, dass der Provider das auf dem Webserver gespeicherte
                      Seitenangebot und unter Umständen auch weitere dort abgelegte Daten
                      des Kunden aus technischer Sicht jederzeit einsehen kann. Auch
                      andere Teilnehmer am Internet sind unter Umständen technisch in der
                      Lage, unbefugt in die Netzsicherheit einzugreifen und den
                      Nachrichtenverkehr zu kontrollieren. Für die Sicherheit der von ihm
                      ins Internet übermittelten und auf Web-Servern gespeicherten Daten
                      trägt der Kunde vollumfänglich selbst Sorge.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>13. Betrug</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>13.1.</Mark>
                    <Paragraph>
                      Wir behalten uns das Recht vor, Maßnahmen zur Umsetzung von
                      Strafverfolgungen und Sanktionen gegen Sie zu ergreifen, wenn Sie in
                      betrügerische, unehrliche oder verbrecherische Handlungen involviert
                      sind, und wir werden diese nach Bedarf an die Behörden melden.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>13.2.</Mark>
                    <Paragraph>
                      Auf Verlangen entschädigen Sie uns für und halten uns schadlos gegen
                      alle Kosten, Forderungen und Verluste (einschließlich aller
                      direkten, indirekten und Folge-Verluste, entgangener Gewinne und
                      Ansehensverlust), die uns direkt oder indirekt durch Ihren Betrug,
                      Ihre Unehrlichkeit oder Ihr verbrecherisches Handeln entstehen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>14. Konflikte</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>14.1.</Mark>
                    <Paragraph>
                      Im Falle von Fragen oder Beschwerden in Verbindung mit unserer
                      Website, unseren Produkten oder Dienstleistungen, sollten Sie als
                      ersten Schritt und so bald es Ihnen möglich ist, unseren
                      Kundendienst kontaktieren.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>14.2.</Mark>
                    <Paragraph>
                      Falls Ihre Anfrage oder Ihre Beschwerde nach der Kontaktierung des
                      Kundendienstes nicht gelöst werden kann, können Sie zur Behebung
                      oder Lösung der Anfrage/Beschwerde einen leitenden Angestellten oder
                      Vorgesetzten verlangen. Unser leitender Angestellte/Vorgesetzter
                      wird Ihre Anfrage/Beschwerde eingehend prüfen und Sie so schnell wie
                      praktisch möglich kontaktieren. Sie werden über den Namen und Status
                      der Person informiert, zu der Ihre Anfrage/Beschwerde weitergeleitet
                      wurde.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <Title>15. Allgemeines</Title>
              <List>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.1.</Mark>
                    <Paragraph>
                      Gesamte Vereinbarung. Diese Nutzungsbedingungen und jegliches
                      Dokument, das hierin ausdrücklich mit diesen in Verbindung gebracht
                      wird, und alle Richtlinien oder Regelungen, die auf unserer Website
                      veröffentlicht sind, stellen die gesamte Vereinbarung dar sowie das
                      Einverständnis zwischen Ihnen und uns in Bezug auf den
                      Vertragsgegenstand der Nutzungsbedingungen und ersetzen alle
                      vorherigen mündlichen oder schriftlichen Vereinbarungen oder
                      Absprachen zwischen Ihnen und uns. Mit der Anerkennung der
                      Nutzungsbedingungen erkennen Sie an und erklären Sie sich
                      einverstanden, dass Sie sich nicht verlassen oder einen Anspruch
                      haben auf Aussagen, Darstellungen, Zusicherungen von Übereinkünften
                      mit (gleich, ob fahrlässiger Natur oder in gutem Glauben gemacht)
                      jedweder Person als in den Nutzungsbedingungen dargelegt. Mit keiner
                      Angabe in diesem Abschnitt ist beabsichtigt, die Haftung für Betrug,
                      betrügerische Absicht oder fahrlässige Falschdarstellung teilweise
                      oder ganz auszuschließen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.2.</Mark>
                    <Paragraph>
                      Verzicht. Keine Verzögerung, kein Fehler und keine Auslassung (im
                      Ganzen oder in Teilen) bei dem Bestreben der Verfolgung oder
                      Durchsetzung von Recht, Macht, Privileg, Ansprüchen oder
                      Rechtsmitteln durch oder entstehend durch diese Nutzungsbedingungen
                      oder via Gesetz, darf als Verzichtserklärung verstanden werden.
                      Ähnlich schließt eine einzelne oder teilweise Ausübung oder
                      Verfolgung von Recht, Macht, Privileg, Ansprüchen oder Rechtsmitteln
                      eine weitere Ausübung derselben in Bezug auf anderes Recht, Macht,
                      Privileg, Ansprüche oder Rechtsmittel nicht aus.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.3.</Mark>
                    <Paragraph>
                      Abtretung, Transfer etc. Sie dürfen Ihre Rechte und/oder Pflichten
                      aus den Nutzungsbedingungen ohne unsere vorherige schriftliche
                      Einverständniserklärung nicht abtreten, übertragen, lizenzieren,
                      sublizenzieren oder hiermit anderweitig handeln. Wir sind dazu
                      berechtigt, unsere Rechte aus den Nutzungsbedingungen nach Ermessen
                      abzutreten, zu übertragen oder hiermit anderweitig zu handeln.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.4.</Mark>
                    <Paragraph>
                      Unschädlichkeit. Falls irgendeine der Nutzungsbedingungen durch ein
                      Gericht, eine Verwaltungsbehörde oder andere zuständige Stellen in
                      irgendeinem Ausmaß als ungültig, ungesetzlich oder uneinklagbar
                      erklärt wird, wird solch eine Bedingung oder Regelung in diesem
                      Ausmaß in den verbleibenden Nutzungsbedingungen, die weiterhin in
                      vollem gesetzlich erlaubtem Maße ihre Gültigkeit behalten, als
                      nichtig erklärt.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.5.</Mark>
                    <Paragraph>
                      Benachrichtungen. Jegliche Benachrichtigung in Zusammenhang mit den
                      Nutzungsbedingungen muss schriftlich und auf folgende Weise
                      erfolgen:
                    </Paragraph>
                  </ListItemWrapper>
                  <List>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>a.</Mark>
                        <Paragraph>
                          Benachrichtigungen an Sie müssen per Email an die gültige
                          Email-Adresse gesendet werden, die Sie uns bei Eröffnung Ihres
                          Kontos bei uns zur Verfügung gestellt haben oder durch über
                          andere Wege, wie sie in Abschnitt 28.5(b)(1.2. a.) bis
                          einschließlich (1.2. c.) dargelegt sind.
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>b.</Mark>
                        <Paragraph>
                          Wenn Sie kein Konto bei uns eröffnet haben, werden
                          Benachrichtigungen wie folgt geliefert: (1.2. a.) per Email an
                          jedwede Emailadresse, die Sie zur Kommunikation mit uns
                          verwendet haben, (1.2. b.) via Post, Kurier oder andere Formen
                          der physischen Zulieferung an Ihre Wohnadresse oder Ihren
                          Arbeitsplatz oder (1.2. c.) durch persönliche Zustellung an Sie.
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                    <ListItem>
                      <ListItemWrapper>
                        <Mark>c.</Mark>
                        <Paragraph>
                          Benachrichtigungen an uns können Sie per Email an den
                          Kundendienst oder an die registrierte Adresse von weCreate Data
                          GmbH, Obere Augartenstraße 2/24, 1020 Wien, Österreich, senden.
                        </Paragraph>
                      </ListItemWrapper>
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.6.</Mark>
                    <Paragraph>
                      Rechte Dritter. Sofern nicht ausdrücklich angegeben, werden in den
                      Nutzungsbedingungen keine Rechte oder irgendwelche Vorteile,
                      entweder gemäß englischer Verträge Act 1999 (Rechte Dritter) oder
                      anderweitiger, an andere Personen außer Ihnen und uns übertragen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.7.</Mark>
                    <Paragraph>
                      Keine Kooperation, Agentur etc. Nichts in diesen Nutzungsbedingungen
                      darf ausgelegt werden als Gründung einer Agentur, Kooperation oder
                      als irgendeine andere Form der Gemeinschaftsunternehmung zwischen
                      Ihnen und uns noch als Genehmigung, als Agent für die andere Partei
                      zu agieren oder als Berechtigung für uns oder Sie, im Namen oder
                      Auftrag die andere Partei in irgendeiner Weise zu binden.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.8.</Mark>
                    <Paragraph>
                      Höhere Gewalt. Weder Sie noch wir sind in irgendeiner Weise haftbar
                      für irgendetwas, ausgenommen von dieser Regelung, was diese
                      Nutzungsbedingungen infolge von höherer Gewalt, und zwar infolge von
                      Ereignissen, die außerhalb Ihrer oder unserer Kontrolle liegen,
                      verletzt, hierunter (aber nicht ausschließlich) Naturereignisse,
                      Terrorismus, See- und Luftfahrtgefahren, Feuer, Überschwemmungen,
                      Dürre, Explosionen, Embargo, Unruhen, Arbeiterstreiks, bürgerliche
                      Unruhen oder Zivilhoheiten, hierunter Handlungen lokaler Regierungen
                      und parlamentarischer Autoritäten, Terroranschlägen, weltweite
                      Computerviren, die grundlegende Internetverbindungen
                      beeinträchtigen.
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
                <ListItem>
                  <ListItemWrapper>
                    <Mark>15.9.</Mark>
                    <Paragraph>
                      Anwendbares Recht und Gerichtsstand. Die Gültigkeit, Auslegung und
                      Erfüllung dieser Nutzungsbedingungen (und jeglicher Anspruch, Streit
                      und jegliche Angelegenheit, die in Verbindung mit ihr oder ihrer
                      Vollstreckbarkeit steht) wird in Übereinstimmung mit den Gesetzen
                      von Österreich geregelt und ausgelegt. Wir und Sie unterwerfen uns
                      in Bezug auf jeden Anspruch, jeden Streitigkeit und jeder
                      Angelegenheit, die in Verbindung mit dieser Vereinbarung steht,
                      unwiderruflich der ausschließlichen juristischen Zuständigkeit der
                      Gerichte von Österreich oder ihrer Vollstreckbarkeit. Stand:
                      01.06.2019
                    </Paragraph>
                  </ListItemWrapper>
                </ListItem>
              </List>
            </ListItem>
          </List>
        </TextPageContainer>
      </LandingLayout>
    </>
  )
}

export default TermsPage
